import { createContext } from "react";
import { MClient, MSite, MBuilding } from "../../models";

import AdminLayout from "../Layout/Admin";
import { MFloor, MSpace } from "../../models/defaultStates";

export const DataContext = createContext({
  client: MClient,
  setClient: (client: any) => {},
  site: MSite,
  setSite: (site: any) => {},
  building: MBuilding,
  setBuilding: (building: any) => {},
  floor: MFloor,
  setFloor: (floor: any) => {},
  space: MSpace,
  setSpace: (space: any) => {},
});

export const ThemeContext = createContext({
  darkMode: false,
  setDarkMode: (mode: boolean) => {},
  layout: AdminLayout,
  setLayout: (layout: any) => {},
});
