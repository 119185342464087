import React from "react";

// MUI
import { IconButton, Menu, MenuItem } from "@material-ui/core";

// MUI Icons
import { MoreVert } from "@material-ui/icons";

// Util
interface IGridMenu {
  layout: any;
  data: any;
  handleRemove: (payload: any) => void;
}

const GridMenu = ({ data, layout, handleRemove }: IGridMenu) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: ".25rem 0" }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() =>
            handleRemove({ layout: layout.filter((l: any) => l.i !== data.id) })
          }
        >
          Remove
        </MenuItem>
      </Menu>
    </div>
  );
};

export default GridMenu;
