import React from "react";

// MUI
import { Typography, makeStyles, Tooltip, Grid } from "@material-ui/core";

// Components
import DeviceDialog from "./DeviceDialog";
import LightControl from "./DraggableGrid/LightControl";
import TempControl from "./DraggableGrid/TempControl";

// Interfaces
import { IDevice, IPoint } from "../../interfaces";
import { DataContext } from "../Contexts";

// Styles
const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  header: {
    textAlign: "center",
    fontSize: ".85rem",
  },
  title: {
    textAlign: "center",
    fontSize: "1.25rem",
  },
  infoContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
  },
});

interface IPointsContainer {
  points: IPoint[];
}
const PointsTooltip = ({ points }: IPointsContainer) => {
  return (
    <span>
      {points.map((point: IPoint) => {
        return <p key={point.id}>{point.name}</p>;
      })}
    </span>
  );
};

interface IDeviceGridComponent {
  data: IDevice;
}
const DeviceGridComponent = ({ data }: IDeviceGridComponent) => {
  const classes = useStyles();

  const { building } = React.useContext(DataContext);

  const floorDictionary = React.useMemo(() => {
    return building.floors.reduce((prev: any, current: any) => {
      prev[current.id] = current.name;
      return prev;
    }, {});
    // eslint-disable-next-line
  }, []);

  const spaceDictionary = React.useMemo(() => {
    return building.spaces.reduce((prev: any, current: any) => {
      prev[current.id] = current.name;
      return prev;
    }, {});
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <div>
        <Grid item xs={12}>
          <Typography align="center" className={classes.header}>
            {`${floorDictionary[data.floorId]} / ${
              spaceDictionary[data.spaceId]
            }`}
          </Typography>
        </Grid>
        <Typography className={classes.title} color="textSecondary">
          {data.name}
        </Typography>
        <Tooltip title={<PointsTooltip points={data.points} />}>
          <Typography variant="body2" component="p" align="center">
            {`${data.points.length} Points`}
          </Typography>
        </Tooltip>
      </div>
      <div className={classes.infoContainer}>
        <DeviceDialog data={data} />
      </div>
      <div style={{ display: "flex" }}>
        <TempControl points={data.points} />
        <LightControl points={data.points} />
      </div>
    </div>
  );
};

export default DeviceGridComponent;
