//Router
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import createRootReducer from "../reducers";
import { getFirebase } from "react-redux-firebase";

//Middleware
//Logger
import logger from "redux-logger";

//Sagas
import createSagaMiddleware from "redux-saga";
import rootSaga from "./../sagas/index";

//Thunk
import thunk from "redux-thunk";

//Create Middleware
export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

export default function configureStore(): any {
  let store;

  store =
    process.env.NODE_ENV === "production"
      ? createStore(
          createRootReducer(history),
          compose(
            applyMiddleware(routerMiddleware(history), sagaMiddleware, thunk)
          )
        )
      : createStore(
          createRootReducer(history),
          compose(
            applyMiddleware(routerMiddleware(history), logger, sagaMiddleware)
          )
        );

  //Run sagas
  // @ts-ignore
  sagaMiddleware.run(rootSaga, getFirebase);

  return store;
}
