import { createMuiTheme } from "@material-ui/core/styles";
import { lightBlue, teal } from "@material-ui/core/colors";

/* 

  TYPOGRAPHY:

  HEADERS:
    Headers alternate between bold and light, decreasing in size. 
    Odd is bold, even is light

    h5, h6: No automatic padding applied due to size

  STATUS:
    Info:     subtitle1
    Warning:  subtitle2
    Error:    caption 

*/

const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: lightBlue[300]
    },
    secondary: {
      main: teal[300]
    }
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": "Roboto Slab"
      }
    },
    MuiDivider: {
      root: {
        width: "100%",
        margin: ".5rem 0 1rem 0"
      }
    }
  },
  typography: {
    fontFamily: ["Roboto Slab", "Roboto", "Helvetica", "sans-serif"].join(","),
    body1: {
      fontSize: "1.25rem",
      fontWeight: 300
    },
    body2: {
      fontSize: "1rem",
      fontWeight: 300
    },
    h1: {
      fontSize: "3.00rem",
      fontWeight: 700
    },
    h2: {
      fontSize: "2.50rem",
      fontWeight: 300
    },
    h3: {
      fontSize: "2.00rem",
      fontWeight: 700
    },
    h4: {
      fontSize: "1.50rem",
      fontWeight: 300
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 700
    },
    h6: {
      fontSize: "1.00rem",
      fontWeight: 300
    },
    // Info
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 300,
      lineHeight: 1.5
    },
    // Warning
    subtitle2: {
      fontSize: "1rem",
      fontWeight: 300,
      lineHeight: 1.5
    },
    // Error
    caption: {
      fontSize: "1rem",
      fontWeight: 300,
      lineHeight: 1.5
    }
  }
});

export default defaultTheme;
