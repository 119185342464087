import React from "react";

// MUI
import { Avatar, Typography, makeStyles } from "@material-ui/core";

// Logo
import TapaLogo from "../../../../../../static/icons/tapaIcon.png";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content"
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Company = () => {
  const classes = useStyles();

  const user = {
    name: "Tapa Center",
    avatar: TapaLogo,
    bio: "www.tapacenter.com"
  };

  return (
    <div className={classes.root}>
      <Avatar
        alt="Company Logo"
        className={classes.avatar}
        src={user.avatar}
        component={"a"}
        href={"https://www.tapacenter.com"}
      />
      <Typography className={classes.name} variant="h4">
        {user.name}
      </Typography>
      <Typography variant="body2">{user.bio}</Typography>
    </div>
  );
};

export default Company;
