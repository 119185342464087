import React from "react";

// Router
import { NavLink as RouterLink } from "react-router-dom";

// MUI
import { Button, Typography, makeStyles, Theme } from "@material-ui/core";

// Colors
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.type === "light" ? grey[50] : grey[700],
    padding: "1rem",
    borderRadius: 4
  },
  media: {
    paddingTop: theme.spacing(2),
    height: 60,
    textAlign: "center",
    "& > img": {
      height: "100%",
      width: "auto"
    }
  },

  actions: {
    padding: theme.spacing(1, 2),
    display: "flex",
    justifyContent: "center"
  }
}));

const Permissions = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.media}>
        <Typography variant="h6">Permissions</Typography>
      </div>
      <div>
        <Typography align="center" gutterBottom variant="h6">
          Contact Forms
        </Typography>
        <Typography align="center" variant="body2">
          Contact your administrator or building engineer to report an issue
        </Typography>
      </div>
      <div className={classes.actions}>
        <Button
          color="secondary"
          component={RouterLink}
          variant="contained"
          to="/permissions"
        >
          Contact
        </Button>
      </div>
    </div>
  );
};

export default Permissions;
