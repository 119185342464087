import { auth } from "./firebase";

interface IAuth {
  email: string;
  password: string;
}

export const doCreateUserWithEmailAndPassword = ({
  email,
  password
}: IAuth) => {
  try {
    return auth.createUserWithEmailAndPassword(email, password);
  } catch (err) {
    console.log(err);
  }
};

// Sign In
export const doSignInWithEmailAndPassword = ({ email, password }: IAuth) =>
  auth.signInWithEmailAndPassword(email, password);

// Sign out
export const doLogOut = () => auth.signOut();

// Password Reset
export const doPasswordReset = (email: string) =>
  auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password: string) => {
  // @ts-ignore
  return auth.currentUser.updatePassword(password);
};
