import React from "react";
import { functions } from "../firebase/firebase";
import { useSelector } from "react-redux";

const fetchEndpoint = functions.httpsCallable("fetchEndpoint");

interface ITapaAPI {
  route: string;
  api: string;
  callback: (res: any) => any;
  error?: () => any | undefined;
}

// No response
const sendError = () => {
  return new Promise((res, rej) => {
    rej("No route or api specified");
  });
};

export const tapaAPI = ({ route, api, callback, error }: ITapaAPI) => {
  if (!route || !api) return sendError();

  return fetchEndpoint({ route, api })
    .then((res) => callback(JSON.parse(res.data)))
    .catch((e: Error) => (error ? error() : console.log(e)));
};

export const useTapa = (route: string) => {
  const profile = useSelector((state: any) => state.firebase.profile);

  interface IData {
    fetching: boolean;
    error: string;
    payload: any[];
  }

  const [data, setData] = React.useState<IData | any>({
    fetching: true,
    error: "",
    payload: [],
  });

  const fetchData = React.useCallback(() => {
    fetchEndpoint({ route, api: profile.api })
      .then((res) =>
        setData({
          fetching: false,
          error: "",
          payload: JSON.parse(res.data),
        })
      )
      .catch((e: Error) => {
        setData({
          fetching: false,
          error: `Failed to fetch from route /${route}`,
          payload: [],
        });
      });
  }, [route, profile.api]);

  return [data, fetchData];
};

export const useSetter = (initialValue: any) => {
  const [value, setValue] = React.useState(initialValue);

  const onChange = React.useCallback(
    (payload: any) => {
      setValue(payload);
    },
    [setValue]
  );

  return [value, onChange];
};
