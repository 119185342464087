import React from "react";

// Interfaces
import { IPoint } from "../../../interfaces";

// MUI
import { IconButton, Typography, makeStyles, Theme } from "@material-ui/core";

// Components
import CustomSlider from "../../Slider/CustomSlider";

// Util
import { find } from "lodash";
import { useAnimations } from "../../../animations";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  expanded: {
    color: theme.palette.primary.main,
  },
  hidden: {
    display: "none",
  },
}));

interface ITempControl {
  points: IPoint[];
}

const TempControl = ({ points }: ITempControl) => {
  const [expand, setExpand] = React.useState(false);

  const styles = useStyles();
  const classes = useAnimations();

  const temp = find(points, ["dataObjectType", "temperature"]);
  return temp ? (
    <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
      <IconButton
        onClick={() => setExpand(!expand)}
        className={clsx({
          [styles.expanded]: expand,
        })}
        disabled={temp.readOnly}
      >
        <i
          className="fas fa-thermometer-quarter"
          style={{ fontSize: "1.25rem" }}
        ></i>
      </IconButton>

      <Typography
        variant="h6"
        style={{
          fontWeight: 200,
          paddingRight: ".5rem",
          overflow: "hidden",
          height: 30,
          flexGrow: 1,
        }}
        className={clsx({
          [styles.hidden]: expand,
        })}
      >
        {temp.data.displayString}
      </Typography>

      <div
        style={{ padding: "0 1rem", marginTop: ".25rem" }}
        className={clsx({
          [classes.slideIn]: expand,
        })}
      >
        <CustomSlider
          value={Number(temp.data.value)}
          hideControls={expand ? false : true}
        />
      </div>
    </div>
  ) : (
    <span style={{ flexGrow: 1 }} />
  );
};

export default TempControl;
