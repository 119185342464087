import React, { ReactElement, useCallback, useState, memo } from "react";

// Components
import { AdminAppBar } from "./components";
// import { useTapa } from "../../../api";

const AdminLayout = (): ReactElement => {
  //   Drawer Handlers
  const [, setOpen] = useState(false);

  // Sample fetch using api hook
  // const [{ fetching, error, payload }, fetchData] = useTapa("clients");

  // React.useEffect(() => {
  //   fetchData();
  // }, []);

  // console.log(fetching, error, payload);

  const handleDrawer = useCallback(
    (val: boolean) => {
      setOpen((o) => val || !o);
    },
    [setOpen]
  );

  return (
    <div style={{ position: "relative", width: "100%", zIndex: 100 }}>
      <AdminAppBar handleDrawer={handleDrawer} />

      {/* <AdminDrawer
        handleDrawer={handleDrawer}
        open={openDesktop}
        variant={isDesktop ? "persistent" : "temporary"}
      /> */}
    </div>
  );
};

export default memo(AdminLayout);
