import React, { useContext, memo } from "react";

// Contexts
import { DataContext } from "../Contexts";

// MUI
import {
  makeStyles,
  Theme,
  Tabs,
  Tab,
  Typography,
  Grid,
} from "@material-ui/core";

// Interfaces
import { ISpace, IDevice } from "../../interfaces";

// Util
import { find } from "lodash";

// Components
import Device from "./Device";
import { useFirebase } from "react-redux-firebase";
import { useSelector } from "react-redux";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
  tabContainer: {
    width: "100%",
    justifyContent: "space-evenly",
  },
  tab: {
    flexGrow: 1,
  },
  deviceContainer: {
    width: "100%",
    justifyContent: "space-evenly",
    padding: "1rem 0",
  },
}));

// Tab Label
interface ILabel {
  name: string;
  floor: string;
}
const Label = ({ name, floor }: ILabel) => {
  return (
    <span style={{ lineHeight: 1 }}>
      {name}
      <br />
      <span style={{ fontSize: ".75rem" }}>{floor}</span>
    </span>
  );
};

// Main Component
const Dashboard = () => {
  const classes = useStyles();

  // Accessors
  const { building, floor } = useContext(DataContext);

  // Accessors
  const profile = useSelector((state: any) => state.firebase.profile);
  const firebase = useFirebase();

  // Update Profile
  const updateProfile = React.useCallback(
    (payload) => {
      firebase.updateProfile(payload);
    },
    // eslint-disable-next-line
    [profile]
  );

  // Labels
  const floorDictionary = React.useMemo(() => {
    return building.floors.reduce((prev: any, current: any) => {
      prev[current.id] = current.name;
      return prev;
    }, {});
    // eslint-disable-next-line
  }, []);

  // Tab controls
  //   @ts-ignore
  const [value, setValue] = React.useState(floor.spaces[0]?.id);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  //   Tabs
  const tabs = React.useMemo(() => {
    return building.spaces.map((space: ISpace) => {
      return (
        <Tab
          key={space.id}
          label={
            <Label name={space.name} floor={floorDictionary[space.floorId]} />
          }
          className={classes.tab}
          value={space.id}
        />
      );
    });
    // eslint-disable-next-line
  }, []);

  if (!floor.spaces || !floor.spaces.length) return null;

  const selectedSpace = find(building.spaces, ["id", value]);

  return (
    <div>
      <div className={classes.tabContainer}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs}
        </Tabs>
      </div>

      <Grid container className={classes.deviceContainer}>
        {selectedSpace &&
          // @ts-ignore
          selectedSpace.devices.map((device: IDevice) => {
            return (
              <Grid
                key={device.id}
                item
                xs={12}
                sm={6}
                md={4}
                style={{ padding: "1rem" }}
              >
                <Device
                  data={device}
                  profile={profile}
                  updateProfile={updateProfile}
                />
              </Grid>
            );
          })}
      </Grid>
      {
        //   @ts-ignore
        selectedSpace && !Boolean(selectedSpace.devices.length) ? (
          <Typography
            variant="h6"
            align="center"
            style={{ width: "100%", padding: "2rem 1rem" }}
          >
            There are no devices attached to this space
          </Typography>
        ) : null
      }
    </div>
  );
};

export default memo(Dashboard);
