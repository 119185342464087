import firebase from "firebase";

import "firebase/auth/dist/index.cjs";
import "firebase/database/dist/index.cjs";

let config;
if (
  process.env.NODE_ENV === "development" ||
  window.location.host.split(".")[0] === "staging"
) {
  config = {
    apiKey: "AIzaSyCdgiF46JXW20HmAtSQC-1occwWiEKNYoE",
    authDomain: "tenant-dev-id.firebaseapp.com",
    databaseURL: "https://tenant-dev-id.firebaseio.com",
    projectId: "tenant-dev-id",
    storageBucket: "tenant-dev-id.appspot.com",
    messagingSenderId: "755895917630",
    appId: "1:755895917630:web:a941e7fb64a253e219856f",
    measurementId: "G-69CQBPXGP1",
  };
} else {
  config = {
    apiKey: "AIzaSyCdgiF46JXW20HmAtSQC-1occwWiEKNYoE",
    authDomain: "tenant-dev-id.firebaseapp.com",
    databaseURL: "https://tenant-dev-id.firebaseio.com",
    projectId: "tenant-dev-id",
    storageBucket: "tenant-dev-id.appspot.com",
    messagingSenderId: "755895917630",
    appId: "1:755895917630:web:a941e7fb64a253e219856f",
    measurementId: "G-69CQBPXGP1",
  };
}

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

//Use Admin SDK
const auth = firebase.auth();
const db = firebase.database();
const functions = firebase.functions();
const store = firebase.firestore();
const storage = firebase.storage();

export { auth, db, functions, storage, store };
