import React from "react";

import { Slider, withStyles, Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  hidden: {
    display: "none",
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
}));

const StyledSlider = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 8,
    padding: ".75rem 0",
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(Slider);

interface ICustomSlider {
  value: number;
  hideControls?: boolean;
}

const CustomSlider = ({ value, hideControls = false }: ICustomSlider) => {
  const classes = useStyles();
  return (
    <div style={{ display: "flex" }}>
      {" "}
      <StyledSlider
        valueLabelDisplay="auto"
        defaultValue={Number(Number(value).toFixed(1))}
        classes={{ thumb: hideControls ? classes.hidden : classes.thumb }}
      />
    </div>
  );
};

export default CustomSlider;
