import { put, takeEvery } from "redux-saga/effects";

//Actions
import * as ACTIONS from "../actions";

import { auth } from "../firebase/firebase";
import { doSignInWithEmailAndPassword } from "../firebase/auth";

function* handleError(
  e: { code: string; email: string; message: string },
  firebase: any
) {
  switch (e.code) {
    case "auth/account-exists-with-different-credential": {
      const type = yield auth.fetchSignInMethodsForEmail(e.email);

      if (type.indexOf("facebook.com") > -1) {
        yield put({
          type: ACTIONS.UPDATE_SESSION_ASPECT,
          aspect: "error",
          payload: "Please log in with Facebook",
        });
      } else if (type.indexOf("google.com") > -1) {
        yield put({
          type: ACTIONS.UPDATE_SESSION_ASPECT,
          aspect: "error",
          payload: "Please log in with Google",
        });
      } else if (type.indexOf("password") > -1) {
        yield put({
          type: ACTIONS.UPDATE_SESSION_ASPECT,
          aspect: "error",
          payload:
            "Email already used using Email and Password. Please log in with Email and Password",
        });
      } else {
        yield put({
          type: ACTIONS.UPDATE_SESSION_ASPECT,
          aspect: "error",
          payload: "Try different login provider",
        });
      }

      break;
    }

    case "auth/email-already-in-use": {
      yield put({
        type: ACTIONS.UPDATE_SESSION_ASPECT,
        aspect: "error",
        payload: `Email already taken. Try signing in if you've signed up already.`,
      });

      break;
    }

    case "auth/user-not-found": {
      yield put({
        type: ACTIONS.UPDATE_SESSION_ASPECT,
        aspect: "error",
        payload: e.message,
      });

      break;
    }

    case "auth/weak-password": {
      yield put({
        type: ACTIONS.UPDATE_SESSION_ASPECT,
        aspect: "error",
        payload: e.message,
      });

      break;
    }

    case "auth/wrong-password": {
      yield put({
        type: ACTIONS.UPDATE_SESSION_ASPECT,
        aspect: "error",
        payload: e.message,
      });

      break;
    }

    default: {
      return null;
    }
  }
}

//Saga Handler
function* handleAuth(action: {
  filter: string;
  firebase: any;
  payload: {
    email: string;
    password: string;
    route?: string;
    reroute?: any;
    firstName?: string;
    lastName?: string;
  };
}) {
  const { filter } = action;

  if (filter === ACTIONS.SIGN_IN_EMAIL) {
    const { firebase, payload } = action;
    const { email, password, route, reroute } = payload;
    try {
      yield doSignInWithEmailAndPassword({
        email,
        password,
      }).then((res: any) => {
        if (route && reroute) {
          reroute(route);
        }
      });
    } catch (e) {
      yield handleError(e, firebase);
    }
  }

  if (filter === ACTIONS.SIGN_UP_EMAIL) {
    const { firebase, payload } = action;
    const { email, password, firstName, lastName } = payload;
    try {
      yield firebase.createUser(
        { email, password },
        { email, firstName, lastName }
      );
    } catch (e) {
      yield handleError(e, firebase);
    }
  }

  if (filter === ACTIONS.SIGN_UP_FACEBOOK) {
    const { firebase } = action;
    try {
      yield firebase.login({
        provider: "facebook",
        type: "popup",
      });
    } catch (e) {
      yield handleError(e, firebase);
    }
  }

  if (filter === ACTIONS.SIGN_UP_GOOGLE) {
    const { firebase } = action;
    try {
      yield firebase.login({
        provider: "google",
        type: "popup",
      });
    } catch (e) {
      yield handleError(e, firebase);
    }
  }
}

export default function* authSaga() {
  // @ts-ignore
  yield takeEvery(ACTIONS.AUTH_SAGA, handleAuth);
}

export {};
