import React from "react";

import CircularSlider from "@fseehawer/react-circular-slider";

// Interfaces
import { IPoint } from "../../interfaces";

import { Button, Typography, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    position: "absolute",
    height: 1,
    top: 200,
    width: 150,
    left: 65,
    background: theme.palette.primary.main,
  },
  booleanContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  booleanButtonWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  booleanContainerTitle: {
    fontSize: "1rem",
    color: "rgb(39, 43, 119)",
  },
  booleanContainerText: {
    zIndex: 9,
    fontSize: "4rem",
    marginBottom: "calc(1.5rem)",
    color: "rgb(39, 43, 119)",
  },
}));

interface ILightSwitch {
  classes: any;
  value: string | number | boolean;
  name: string;
}

const LightSwitch = ({ classes, value, name }: ILightSwitch) => {
  return (
    <div className={classes.booleanContainer}>
      <code className={classes.booleanButtonWrapper}>
        <Typography variant="body1" className={classes.booleanContainerTitle}>
          {name}
        </Typography>
        <Button className={classes.booleanContainerText}>
          {value ? "On" : "Off"}
        </Button>
      </code>
    </div>
  );
};

interface ILights {
  data: IPoint;
}

const Lights = ({ data }: ILights) => {
  const classes = useStyles();

  const handleChange = (value: number) => {
    // console.log(value);
  };

  if (data.dataType === "boolean") {
    return (
      <div style={{ position: "relative" }}>
        <CircularSlider
          min={0}
          max={100}
          direction={1}
          knobPosition="bottom"
          renderLabelValue={
            <LightSwitch
              classes={classes}
              value={data.data.value}
              name={data.name}
            />
          }
          dataIndex={data.data.value ? 100 : 0}
          appendToValue=""
          valueFontSize="4rem"
          onChange={(value: number) => handleChange(value)}
          hideKnob={true}
          knobDraggable={false}
        />
        <div className={classes.divider} />
      </div>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      <CircularSlider
        min={0}
        max={100}
        direction={1}
        knobPosition="bottom"
        label={data.name}
        dataIndex={Number(Number(data.data.value).toFixed(0))}
        appendToValue=""
        valueFontSize="4rem"
        onChange={(value: number) => handleChange(value)}
        hideKnob={data.readOnly}
        knobDraggable={!data.readOnly}
      />
      <div className={classes.divider} />
    </div>
  );
};

export default Lights;
