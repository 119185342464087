import React from "react";

import CircularSlider from "@fseehawer/react-circular-slider";

// Interfaces
import { IPoint } from "../../interfaces";

import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    position: "absolute",
    height: 1,
    top: 200,
    width: 150,
    left: 65,
    background: theme.palette.primary.main,
  },
}));

interface ITemperature {
  data: IPoint;
}

const Temperature = ({ data }: ITemperature) => {
  const classes = useStyles();

  const handleChange = (value: number) => {
    // console.log(value);
  };

  return (
    <div style={{ position: "relative" }}>
      <CircularSlider
        min={0}
        max={130}
        direction={1}
        knobPosition="bottom"
        label={data.name}
        dataIndex={Number(Number(data.data.value).toFixed(0))}
        appendToValue="°"
        valueFontSize="4rem"
        onChange={(value: number) => handleChange(value)}
        hideKnob={data.readOnly}
        knobDraggable={!data.readOnly}
      />
      <div className={classes.divider} />
    </div>
  );
};

export default Temperature;
