export const RESET_APP = "RESET_APP";

//Sagas
export const APP_SAGA = "APP_SAGA";
export const AUTH_SAGA = "AUTH_SAGA";
export const DATA_SAGA = "DATA_SAGA";
export const POST_SAGA = "POST_SAGA";
export const USER_SAGA = "USER_SAGA";

//Aspects
export const UPDATE_APP_ASPECT = "UPDATE_APP_ASPECT";
export const UPDATE_AUTH_ASPECT = "UPDATE_AUTH_ASPECT";
export const UPDATE_CONSTANT_ASPECT = "UPDATE_CONSTANT_ASPECT";
export const UPDATE_DATA_ASPECT = "UPDATE_DATA_ASPECT";
export const UPDATE_SELECTION_ASPECT = "UPDATE_SELECTION_ASPECT";
export const UPDATE_SESSION_ASPECT = "UPDATE_SESSION_ASPECT";
export const UPDATE_USER_ASPECT = "UPDATE_USER_ASPECT";

//Reducer
export const UPDATE_APP_REDUCER = "UPDATE_APP_REDUCER";
export const UPDATE_CONSTANT_REDUCER = "UPDATE_CONSTANT_REDUCER";
export const UPDATE_DATA_REDUCER = "UPDATE_DATA_REDUCER";
export const UPDATE_SESSION_REDUCER = "UPDATE_SESSION_REDUCER";
export const UPDATE_USER_REDUCER = "UPDATE_USER_REDUCER";

//SESSION
export const AUTH_USER_SET = "AUTH_USER_SET";
export const LOG_OUT = "LOG_OUT";

export const SIGN_IN_EMAIL = "SIGN_IN_EMAIL";
export const SIGN_UP_EMAIL = "SIGN_UP_EMAIL";
export const SIGN_IN_FACEBOOK = "SIGN_IN_FACEBOOK";
export const SIGN_UP_FACEBOOK = "SIGN_UP_FACEBOOK";
export const SIGN_IN_GOOGLE = "SIGN_IN_GOOGLE";
export const SIGN_UP_GOOGLE = "SIGN_UP_GOOGLE";
export const SIGN_IN_GITHUB = "SIGN_IN_GITHUB";

//Data
export const CHANGE_SETPOINT = "CHANGE_SETPOINT";
export const UPDATE_ALARMS = "UPDATE_ALARMS";

// Status
export const DARK_MODE = "DARK_MODE";

// Actions
export const addGroup = (group: any) => ({
  type: "ADD_GROUP",
  payload: group
});

export const overwriteGroups = (groups: any) => ({
  type: "OVERWRITE_GROUPS",
  payload: groups
});

// Dashboard Fetches
export const SET_CLIENTS = "SET_CLIENTS";
export const SET_BUILDING_DATA = "SET_BUILDING_DATA";
export const REFRESH_BUILDING_DATA = "REFRESH_BUILDING_DATA";
