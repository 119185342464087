//Actions
import * as ACTIONS from "../actions";

const INITIAL_STATE = {
  darkmode: false
};

const stylesReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    // Global style state goes here
    // Deprecated with hook providers
    case ACTIONS.DARK_MODE: {
      return { ...state, darkmode: action.payload };
    }

    default:
      return state;
  }
};

export default stylesReducer;
