import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

// Styles
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

// MUI
import {
  AppBar,
  CssBaseline,
  IconButton,
  Toolbar,
  Button,
} from "@material-ui/core";

// MUI Icons
import { Dashboard, Home, Person } from "@material-ui/icons";

// Util
import clsx from "clsx";
import { lightGreen } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      color: theme.palette.primary.main,
    },
    appBar: {
      top: "auto",
      bottom: 0,
      backgroundColor: theme.palette.background.paper,
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-evenly",
    },
    toolbarEditing: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

interface IMobileAppBar {
  handleSetRoute: (route: string) => void;
  route: string;
  reroute: (path: string) => any;
  editing: boolean;
  setEditing: any;
  handleUpdate: any;
}

const MobileAppBar = ({
  handleSetRoute,
  route,
  reroute,
  editing,
  setEditing,
  handleUpdate,
}: IMobileAppBar) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        {!editing ? (
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => handleSetRoute("/home")}
            >
              <Home
                className={clsx({
                  [classes.active]: route === "/home",
                })}
              />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={() => handleSetRoute("/dashboard")}
            >
              <Dashboard
                className={clsx({
                  [classes.active]: route === "/dashboard",
                })}
              />
            </IconButton>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => handleSetRoute("/profile")}
            >
              <Person
                className={clsx({
                  [classes.active]: route === "/profile",
                })}
              />
            </IconButton>
          </Toolbar>
        ) : (
          <Toolbar className={classes.toolbarEditing}>
            <Button
              variant="outlined"
              aria-label="open drawer"
              onClick={() => setEditing(false)}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => handleUpdate()}
              style={{ background: lightGreen[500], color: "white" }}
            >
              Save
            </Button>
          </Toolbar>
        )}
      </AppBar>
    </React.Fragment>
  );
};

export default connect(null, (dispatch) => ({
  reroute: (route: string) => dispatch(push(route)),
}))(MobileAppBar);
