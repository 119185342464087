import { deepOrange } from "@material-ui/core/colors";

export const loginStyles = {
  root: {
    backgroundColor: "transparent"
  },
  cardHeaderRoot: {
    padding: 0
  },
  slide: {
    height: 150,
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    userSelect: "none"
  },
  error: {
    color: "red"
  },
  subheader: {
    color: deepOrange[500]
  },
  focusHighlight: {
    backgroundColor: "transparent"
  },

  cssFocused: {},

  notchedOutline: {},
  card: {
    justifyContent: "center",
    height: 550,
    maxWidth: 345,
    padding: "2em",
    margin: "0 auto",
    position: "relative",
    borderRadius: 0,
    boxShadow: "none"
  },
  cardHeader: {
    textAlign: "center",
    paddingBottom: "2rem"
  },
  cardContent: {
    position: "relative",
    padding: 0
  },
  slideContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1em"
  },
  textField: {
    fontSize: "1em"
  },
  noLeftBorder: {
    borderLeft: "unset"
  },
  margin: {
    margin: "1em 0"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between"
    // margin: "1em"
  },
  button: {
    color: "white",
    borderRadius: 0,
    padding: "1em"
  },
  bottomButton: {
    fontSize: "1em",
    letterSpacing: ".2em",
    borderRadius: 0,
    color: "white"
  },
  flipButton: {
    justifyContent: "space-between",
    padding: "1em 2em",
    borderRadius: 0,
    position: "absolute",
    bottom: 0,
    left: 0
  },
  icon: {
    transition: "transform .1s ease-in-out"
  },
  rotated: {
    transform: "rotate(180deg)"
  },
  rotatedY: {
    transform: "rotateY(180deg)"
  }
};
