import React, { memo } from "react";
import { connect } from "react-redux";

//MUI
import {
  AppBar,
  CircularProgress,
  Hidden,
  IconButton,
  Toolbar,
  // Util
  makeStyles,
  createStyles,
  // Interfaces
  Theme,
  Typography
} from "@material-ui/core";

// MUI Icons
import { Menu } from "@material-ui/icons";

// Utils
import clsx from "clsx";
import { isLoaded } from "react-redux-firebase";
import { useAnimations } from "../../../../animations";

// Interfaces
import { IAuth, IProfile } from "../../../../interfaces";

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // Base
    root: {
      flexGrow: 1
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      color: theme.palette.text.secondary
    },
    flexGrow: {
      flexGrow: 1
    },
    title: {
      flexGrow: 1
    },
    // Logo
    imgWrapper: {
      display: "flex",
      alignItems: "center",
      borderRadius: 4,
      height: 40
    },
    paper: {
      background: theme.palette.background.paper
    }
  })
);

// Interfaces
interface Props {
  auth: IAuth;
  profile: IProfile;
  handleDrawer: (open: any) => void;
}

const TenantAppBar = ({ profile, handleDrawer }: Props) => {
  const classes = useStyles();
  const animations = useAnimations();

  return (
    <div className={clsx(classes.root, animations.fadeIn)}>
      <AppBar position="static" classes={{ colorPrimary: classes.paper }}>
        <Toolbar className={classes.toolbar}>
          {/* Logo */}
          {!isLoaded(profile) ? (
            <CircularProgress size={20} />
          ) : (
            <Typography variant="h6">Welcome, Tenant</Typography>
          )}

          {/* Flex */}
          <div className={classes.flexGrow} />

          {/* Menu */}
          <Hidden lgUp>
            <IconButton color="inherit" onClick={() => handleDrawer(true)}>
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default connect((state: any) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile
}))(memo(TenantAppBar));
