import React, { forwardRef, memo, useCallback } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";

import { doLogOut } from "../../../../../../../firebase/auth";

// MUI
import {
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Theme
} from "@material-ui/core";

// MUI Colors
import { red } from "@material-ui/core/colors";

// MUI Icons
import {
  AccountBox,
  ExpandLess,
  ExpandMore,
  PowerSettingsNew,
  Settings,
  InsertChart,
  Whatshot,
  WbIncandescent,
  Dashboard
} from "@material-ui/icons";

// Main Styles
const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 0
  },
  nested: {
    paddingLeft: theme.spacing(3)
  },
  logout: {
    color: red[500]
  }
}));

// Link Styles
const useLinkClasses = makeStyles((theme: Theme) => ({
  button: {
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    color: theme.palette.text.secondary
  },
  icon: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  },
  grow: {
    flexGrow: 1
  },
  active: {
    color: theme.palette.primary.main,
    "& $icon": {
      color: theme.palette.primary.main
    },
    "& $button": {
      color: theme.palette.primary.main
    }
  }
}));

// Link Array
const links = [
  {
    title: "Dashboard",
    href: "/dashboard",
    icon: <Dashboard />
  },
  {
    title: "Temperature",
    href: "/temperature",
    icon: <Whatshot />
  },
  {
    title: "Lights",
    href: "/lights",
    icon: <WbIncandescent />
  },
  {
    title: "Usage",
    href: "/history",
    icon: <InsertChart />
  },
  {
    title: "Profile",
    href: "/profile",
    icon: <AccountBox />
  },
  {
    title: "Settings",
    href: "/settings",
    icon: <Settings />
  }
];

// Interfaces
interface ILink {
  title: string;
  href: string;
  icon: JSX.Element;
}

interface ILinks extends ILink {
  children?: Array<ILink>;
}

interface ILinkComponent {
  key?: string;
  linkClasses: any;
  link: ILink;
}

// Components
const CustomRouterLink = forwardRef((props: any, ref: any) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const Link = ({ linkClasses, link }: ILinkComponent) => {
  return (
    <Button
      activeClassName={linkClasses.active}
      className={linkClasses.button}
      component={CustomRouterLink}
      to={link.href}
    >
      <div className={linkClasses.icon}>{link.icon}</div>
      <div className={linkClasses.grow}>{link.title}</div>
    </Button>
  );
};

// Main
const Links = () => {
  // Styles
  const classes = useStyles();
  const linkClasses = useLinkClasses();

  // Nested Menu Open
  const [open, setOpen] = React.useState<string[]>([]);

  // Location
  const location = useLocation();
  const { pathname } = location;

  const handleOpen = useCallback(
    (href: string) => {
      setOpen(o =>
        o.includes(href) ? o.filter(link => link !== href) : [...o, href]
      );
    },
    [setOpen]
  );

  // Open based on location
  React.useEffect(() => {
    // Open submenu based on root path
    handleOpen(`/${pathname.split("/", 2)[1]}`);
    // eslint-disable-next-line
  }, []);

  return (
    <List>
      {links.map((link: ILinks) => (
        <ListItem className={classes.item} disableGutters key={link.title}>
          {/* Render Link */}
          <Link linkClasses={linkClasses} link={link} />

          {/* Render Submenu Icon */}
          {link.children && (
            <IconButton onClick={() => handleOpen(link.href)}>
              {open.includes(link.href) ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}

          {/* Render Submenu */}
          {link.children && (
            <Collapse
              in={open.includes(link.href)}
              timeout="auto"
              unmountOnExit
              style={{ width: "100%" }}
            >
              <List className={classes.nested}>
                {link.children.map((nestedLink: ILink) => {
                  return (
                    <Link
                      key={nestedLink.href}
                      linkClasses={linkClasses}
                      link={nestedLink}
                    />
                  );
                })}
              </List>
            </Collapse>
          )}
        </ListItem>
      ))}
      <ListItem className={classes.item} disableGutters>
        <Button className={classes.logout} onClick={() => doLogOut()}>
          <div className={linkClasses.icon}>{<PowerSettingsNew />}</div>
          Log Out
        </Button>
      </ListItem>
    </List>
  );
};

export default memo(Links);
