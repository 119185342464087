/* eslint-disable */

import React from "react";

// MUI
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Typography,
} from "@material-ui/core";

import { Info, NotificationImportant, Warning } from "@material-ui/icons";

// Interfaces
import { IAlarm } from "../../interfaces";
import { lightBlue, deepOrange, red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    avatarRoot: {
      backgroundColor: "transparent",
    },
    listItem: {
      // display: "flex",
    },
    listItemText: {
      width: "100%",
      overflow: "hidden",
      maxHeight: 60,
    },
  })
);

interface Props {
  alarm: IAlarm;
}

const Alarm = (props: Props) => {
  const classes = useStyles();

  const {
    alarm: {
      pointName,
      location: { siteName, buildingName, floorName, spaceName, deviceName },
      priority,
      startTime,
      endTime,
      userAction: { action },
      alarmMessage,
      alertThreshold,
      currentValue,
      state,
      acknowledged,
    },
  } = props;

  const icon = [
    <Info style={{ color: lightBlue[300] }} />,
    <NotificationImportant style={{ color: deepOrange[200] }} />,
    <Warning style={{ color: red[300] }} />,
  ][priority];

  return (
    <>
      <ListItem dense className={classes.listItem}>
        <ListItemAvatar>
          <Avatar classes={{ root: classes.avatarRoot }}>{icon}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={pointName}
          secondary={alarmMessage}
          className={classes.listItemText}
        />
        {/* <ListItemText
          secondary={`${buildingName} > ${floorName} > ${spaceName} > ${deviceName}`}
          style={{ width: "100%" }}
        /> */}
      </ListItem>{" "}
      <Divider />
    </>
  );
};

export default Alarm;
