import React, { ReactElement, useCallback, useState, memo } from "react";

// MUI
import { useMediaQuery, useTheme } from "@material-ui/core";

// Components
import { TenantAppBar, TenantDrawer } from "./components";

const TenantLayout = (): ReactElement => {
  const theme = useTheme();

  //   Mobile / Desktop
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });

  //   Drawer Handlers
  const [open, setOpen] = useState(false);
  const openDesktop = isDesktop ? true : open;

  const handleDrawer = useCallback(
    (val: boolean) => {
      setOpen(o => val || !o);
    },
    [setOpen]
  );

  return (
    <div style={{ height: "100%" }}>
      <TenantAppBar handleDrawer={handleDrawer} />

      <TenantDrawer
        open={openDesktop}
        handleDrawer={handleDrawer}
        variant={isDesktop ? "persistent" : "temporary"}
      />
    </div>
  );
};

export default memo(TenantLayout);
