import React, { ReactElement, useContext } from "react";

import { ThemeContext } from "../../../../../../Contexts";

// MUI
import { Button, makeStyles, Theme } from "@material-ui/core";
import AdminLayout from "../../../../../Admin";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1rem"
  },
  button: {
    margin: "1rem 0"
  }
}));

export default function ThemeOptions(): ReactElement {
  const classes = useStyles();

  const { darkMode, setDarkMode } = useContext(ThemeContext);
  const { setLayout } = useContext(ThemeContext);

  return (
    <div className={classes.container}>
      <Button
        variant="outlined"
        onClick={() => setDarkMode(!darkMode)}
        className={classes.button}
      >
        {darkMode ? "Light Theme" : "Dark Theme"}
      </Button>
      <Button variant="outlined" onClick={() => setLayout(AdminLayout)}>
        Admin Layout
      </Button>
    </div>
  );
}
