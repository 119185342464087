import React from "react";
import classNames from "classnames";

// Redux
import { connect } from "react-redux";
import { isEmpty, isLoaded } from "react-redux-firebase";
import { push } from "connected-react-router";

//MUI
import {
  Button,
  Card,
  CardHeader,
  Grid,
  InputAdornment,
  Slide,
  TextField,
  Container,
} from "@material-ui/core";

// MUI Hooks
import { makeStyles } from "@material-ui/core/styles";

//Icons
import { Visibility, VisibilityOff } from "@material-ui/icons";

//Sagas
import {
  AUTH_SAGA,
  SIGN_IN_EMAIL,
  SIGN_UP_EMAIL,
  UPDATE_SESSION_ASPECT,
} from "../../actions";

import TapaLogo from "../../static/icons/tapaIcon.png";

import { DASHBOARD } from "../../routes";

// Interfaces
import { ILocation } from "../../interfaces";

import { loginStyles } from "../../assets/styles/loginStyles";
// @ts-ignore
const useStyles = makeStyles(loginStyles);

// Interfaces
interface ILoginCard {
  auth: any;
  error: string;
  location: ILocation;
  dispatch: any;
  signInEmail: (
    payload: { email: string; password: string; route: string; reroute?: any },
    dispatch: any
  ) => void;
  reroute: (route: string) => void;
  clearErrors: () => void;
}

const LoginCard = ({
  auth,
  error,
  location,
  dispatch,
  signInEmail,
  reroute,
  clearErrors,
}: ILoginCard) => {
  const classes = useStyles();
  // Hooks
  const [errors, setErrors] = React.useState("");
  const [isFlipped] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const [state, setState] = React.useState({
    loginEmail: "",
    loginPassword: "",
  });
  const { loginEmail, loginPassword } = state;

  // Cleanup
  React.useEffect(() => {
    if (isLoaded(auth) && !isEmpty(auth)) {
      console.log("Rerouting");
      reroute(DASHBOARD);
    }

    return () => {
      setErrors("");
      clearErrors();
    };
  }, [auth, reroute, clearErrors]);

  // Handlers
  // TextField
  const handleChange = (name: string) => (e: any) => {
    setState({ ...state, [name]: e.target.value });
  };

  // Submit
  const handleSignIn = (e: any) => {
    e.preventDefault();
    signInEmail(
      {
        email: loginEmail,
        password: loginPassword,
        route: location.pathname,
        reroute,
      },
      dispatch
    );
  };

  return (
    <Container
      maxWidth="md"
      style={{
        justifyContent: "flex-end",
        position: "relative",
        marginTop: "1rem",
      }}
    >
      <Card className={classes.card}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "2rem",
          }}
        >
          <img alt={"tapa logo"} src={TapaLogo} height={80} />
        </div>
        {errors ? (
          <CardHeader
            className={classNames(classes.cardHeader)}
            title={isFlipped ? "Email in Use" : "SIGN IN"}
            subheader={errors}
          />
        ) : (
          <CardHeader
            className={classNames(classes.cardHeader)}
            title={isFlipped ? "SIGN UP" : "SIGN IN"}
            classes={{
              root: classes.cardHeaderRoot,
              subheader: error ? classes.subheader : null,
            }}
            subheader={
              error
                ? error
                : isFlipped
                ? "If you were given an API key, enter it below."
                : "Enter your email and password to login"
            }
          />
        )}

        {/* Default */}
        <Slide direction="left" in={!isFlipped} mountOnEnter unmountOnExit>
          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <form action="/" onSubmit={handleSignIn}>
              <Grid item xs={12}>
                <TextField
                  className={classNames(classes.textField, classes.margin)}
                  onChange={handleChange("loginEmail")}
                  required
                  variant="outlined"
                  fullWidth
                  value={loginEmail}
                  label="Email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classNames(classes.textField, classes.margin)}
                  required
                  variant="outlined"
                  label={"Password"}
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  value={loginPassword}
                  onChange={handleChange("loginPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword ? (
                          <VisibilityOff
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        ) : (
                          <Visibility
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "1em" }}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleSignIn}
                  disabled={loginEmail === "" || loginPassword === ""}
                >
                  sign in
                </Button>
              </Grid>
            </form>
          </Grid>
        </Slide>
      </Card>
    </Container>
  );
};

export default connect(
  (state: any) => ({
    auth: state.firebase.auth,
    error: state.session.error,
    location: state.router.location,
  }),
  (dispatch: any) => ({
    dispatch,
    reroute: (route: string) => dispatch(push(route)),
    clearErrors: () => {
      dispatch({
        type: UPDATE_SESSION_ASPECT,
        aspect: "error",
        payload: "",
      });
    },
    signInEmail: (payload: any) =>
      dispatch({
        type: AUTH_SAGA,
        filter: SIGN_IN_EMAIL,
        payload,
      }),
    signUpEmail: (payload: any, dispatch: any) =>
      dispatch({
        type: AUTH_SAGA,
        filter: SIGN_UP_EMAIL,
        payload,
        dispatch,
      }),
  })
)(LoginCard);
