//Actions
import * as ACTIONS from "../actions";

const INITIAL_STATE = {
  clients: [],
  buildingData: {}
};

const apiReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case ACTIONS.SET_CLIENTS: {
      return { ...state, clients: action.payload };
    }

    case ACTIONS.SET_BUILDING_DATA: {
      return {
        ...state,
        buildingData: {
          ...state.buildingData,
          [action.payload[0].id]: action.payload[0]
        }
      };
    }

    default:
      return state;
  }
};

export default apiReducer;
