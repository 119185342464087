import React from "react";
import { Route } from "react-router-dom";

// MUI
import { makeStyles, Theme } from "@material-ui/core";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
  content: {
    height: "100%",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 240,
    },
  },
}));

// Interfaces
interface IStyledRoute {
  component: React.ReactType<any>;
  exact?: boolean;
  path: string;
}

export default function StyledRoute(props: IStyledRoute) {
  const classes = useStyles();
  const { component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <div className={classes.content}>
          <Component {...matchProps} />
        </div>
      )}
    />
  );
}
