import React from "react";

import { makeStyles, Theme, Typography, Grid, Switch } from "@material-ui/core";

// Interfaces
import { ILayout } from "../../../interfaces";

// Contexts
import { DataContext } from "../../Contexts";

// Util
import { find } from "lodash";

// Components
import CustomSlider from "../../Slider/CustomSlider";
import DeviceGridComponent from "../DeviceGridComponent";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },

  title: {
    textAlign: "center",
    lineHeight: 1,
    padding: ".25rem",
  },
  value: {},
  header: {
    textAlign: "center",
    fontSize: ".85rem",
  },
}));

interface IGridComponent {
  layout: ILayout;
  data: any;
}

const GridComponent = ({ layout, data }: IGridComponent) => {
  const classes = useStyles();
  const { building } = React.useContext(DataContext);

  const [switchToggle, setSwitchToggle] = React.useState(data?.data?.value);

  const floorDictionary = React.useMemo(() => {
    return building.floors.reduce((prev: any, current: any) => {
      prev[current.id] = current.name;
      return prev;
    }, {});
    // eslint-disable-next-line
  }, []);

  // Handlers
  const handleToggle = React.useCallback(() => {
    setSwitchToggle((val: boolean) => !val);
  }, []);

  // USE DEVICE
  // @ts-ignore
  if (data && data.points && Boolean(data.points.length)) {
    // @ts-ignore
    return <DeviceGridComponent data={data} />;
  }
  if (!data) return null;

  let device = find(building.devices, ["id", data.deviceId]) || {
    floorId: "",
    name: "",
  };

  // USE POINT
  // Convenience
  const useBoolean = data.dataType === "boolean";
  // const booleanDisplay = data.data.value ? "On" : "Off";

  return (
    <Grid container className={classes.container}>
      {device && (
        <Grid item xs={12}>
          <Typography align="center" className={classes.header}>
            {floorDictionary[device.floorId]} / {device.name}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography
          color="textSecondary"
          align="center"
          className={classes.title}
        >
          {data.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {!useBoolean && (
          <Typography
            color="textSecondary"
            align="center"
            className={classes.title}
          >
            {data.data.displayString}
          </Typography>
        )}
      </Grid>

      {useBoolean ? (
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Switch
            checked={switchToggle}
            color="primary"
            onChange={handleToggle}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <CustomSlider value={Number(Number(data.data.value).toFixed(0))} />
        </Grid>
      )}
    </Grid>
  );
};

export default GridComponent;
