export const MClient = {
  id: "",
  name: "",
  sites: [],
};

export const MSite = {
  id: "",
  name: "",
  buildings: [
    {
      id: "",
    },
  ],
};

export const MBuilding = {
  id: "",
  name: "",
  siteId: "",
  city: "",
  address: "",
  zip: "",
  contacts: [],
  floors: [
    {
      id: "",
      spaces: [],
    },
  ],
  spaces: [],
  devices: [],
};

export const MFloor = {
  id: "",
  spaceId: "",
  devices: [],
  spaces: [],
};

export const MSpace = {
  id: "",
  devices: [],
};
