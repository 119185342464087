import React from "react";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

// Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import { SET_CLIENTS } from "../actions";

//Router
import { ConnectedRouter, push } from "connected-react-router";
import { history } from "../store";
import Routes from "../routes";

//Styles
import { MuiThemeProvider, createMuiTheme, Button } from "@material-ui/core";
import { defaultTheme } from "../assets/themes";

// Components
import AdminLayout from "./Layout/Admin";
import Loader from "./Loader";
import LoginCard from "./App/LoginCard";

// Interfaces / Contexts / Models
import { IDashboardClient } from "../interfaces";
import { ThemeContext, DataContext } from "./Contexts";
import { MBuilding, MFloor, MSpace } from "../models";

// API
import { tapaAPI, useSetter } from "../api";

interface Props {
  firebase: { auth: any; profile: any };
  reroute: (route: string) => void;
  clients: IDashboardClient[];
  setClients: any;
}

const App: React.FC<Props> = ({
  firebase: { auth, profile },
  reroute,
  clients,
  setClients,
}) => {
  // Dark mode to feed context
  const [darkMode, setDarkMode] = React.useState(false);
  const [layout, setLayout] = React.useState<any>(AdminLayout);

  // Set up Providers
  const [client, setClient] = useSetter({ id: "", name: "", sites: [] });
  const [site, setSite] = useSetter({ id: "", name: "", buildings: [] });
  const [building, setBuilding] = useSetter(MBuilding);
  const [floor, setFloor] = useSetter(MFloor);
  const [space, setSpace] = useSetter(MSpace);

  // Client Fetch
  const [fetching, setFetching] = React.useState(false);
  const [error, setError] = React.useState({ title: "", message: "" });

  // Fetch Clients
  const fetchClients = () => {
    if (error.title || error.message) {
      setError({ title: "", message: "" });
    }

    setFetching(true);
    tapaAPI({
      route: "clients",
      api: profile.api,
      callback: handleSetClients,
      error: () =>
        setError({
          title: "Error Fetching Buildings",
          message: "This has been logged and we are working on a solution",
        }),
    }).then((res: any) => {
      setFetching(false);
    });
  };

  // fetchingClients callback
  const handleSetClients = (clients: any) => {
    setClients(clients);
    setFetching(false);
  };

  // If user has an API and no clients, load in clients
  React.useEffect(() => {
    if (profile.api && !clients.length && !fetching) {
      fetchClients();
    }
    // eslint-disable-next-line
  }, [profile.api, setClients]);

  // While auth loading, fetching, or awaiting clients - show loader
  if (
    // Loading Auth
    (auth.isEmpty && !auth.isLoaded && profile.isEmpty && !profile.isLoaded) ||
    // Fetching Clients
    fetching ||
    // Awaiting Clients
    (!auth.isEmpty && !Boolean(clients.length))
  ) {
    return (
      <Loader
        title={"Loading "}
        trigger={fetching || !clients.length}
        error={error}
        retry={
          error && (
            <Button onClick={() => fetchClients()} variant="outlined">
              Reconnect
            </Button>
          )
        }
      />
    );
  }

  // If no auth, display login card
  if (auth.isLoaded && auth.isEmpty) {
    return <LoginCard />;
  }

  // Build theme out of whitelabel
  const { whitelabel: { primary = "", secondary = "" } = {} } = profile;

  const theme = createMuiTheme({
    palette: {
      type: darkMode ? "dark" : "light",
      primary: {
        main: primary ? primary : defaultTheme.palette.primary.main,
      },
      secondary: {
        main: secondary ? secondary : defaultTheme.palette.secondary.main,
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeContext.Provider
        value={{ darkMode, setDarkMode, layout, setLayout }}
      >
        <DataContext.Provider
          value={{
            client,
            setClient,
            site,
            setSite,
            building,
            setBuilding,
            floor,
            setFloor,
            space,
            setSpace,
          }}
        >
          <ConnectedRouter history={history}>
            <Routes theme={theme} />
          </ConnectedRouter>
        </DataContext.Provider>
      </ThemeContext.Provider>
    </MuiThemeProvider>
  );
};

export default compose(
  connect(
    (state: any) => ({
      firebase: state.firebase,
      clients: state.api.clients,
    }),
    (dispatch) => ({
      reroute: (route: string) => dispatch(push(route)),
      setClients: (clients: Array<IDashboardClient>) =>
        dispatch({ type: SET_CLIENTS, payload: clients }),
    })
  ),
  withFirebase
)(App);
