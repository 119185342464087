import React, { ReactElement } from "react";

// Interfaces
interface IRouteWithLayout {
  component: React.ReactType<any>;
}

export default function Layout(props: IRouteWithLayout): ReactElement {
  const { component: Component, ...rest } = props;

  return <Component {...rest} />;
}
