import React from "react";

// MUI
import { Divider, Drawer, makeStyles, Theme } from "@material-ui/core";

import { Company, Links, Permissions } from "./TenantDrawer/components";

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 65,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

interface ITenantDrawer {
  open: boolean;
  variant: "permanent" | "persistent" | "temporary";
  handleDrawer: any;
}

const TenantDrawer = ({ open, variant, handleDrawer }: ITenantDrawer) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={() => handleDrawer(false)}
      open={open}
      variant={variant}
    >
      <div className={classes.root}>
        <Company />
        {/* <ThemeOptions /> */}
        <Divider className={classes.divider} />
        <Links />
        <Permissions />
      </div>
    </Drawer>
  );
};

export default TenantDrawer;
