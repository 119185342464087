import React, { ReactElement } from "react";

// MUI
import { Typography, makeStyles, createStyles, Theme } from "@material-ui/core";

// Styles
import { useAnimations } from "../../animations";
import clsx from "clsx";

// Images
import BrandIcon from "../../static/icons/tapaIcon.png";
import ErrorIcon from "../../static/icons/errorIcon.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      height: "100vh",
      width: "100vw",
      top: 0,
      zIndex: 90000,
      background: theme.palette.background.paper
    },
    container: {
      height: 100,
      width: 100,
      margin: "20% auto 0 auto"
    },
    logo: {
      opacity: 0
    }
  })
);

interface Props {
  title: string;
  trigger: boolean;
  error?: { title: string; message: string };
  retry?: JSX.Element;
}

export default function Loader({
  title,
  trigger,
  error,
  retry
}: Props): ReactElement {
  const classes = useStyles();
  const animations = useAnimations();

  const animationOptions = clsx({
    [animations.fadeIn]: trigger
    // [animations.fadeOut]: !trigger
  });

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.container, {
          [animations.rotate]: error && !error.title && !error.message
        })}
      >
        <img
          className={animationOptions}
          src={error && (error.title || error.message) ? ErrorIcon : BrandIcon}
          alt={"logo"}
          height={"100%"}
          style={{ cursor: "pointer" }}
        />
      </div>
      <Typography
        variant="h6"
        align="center"
        style={{ padding: "2rem", fontWeight: 300 }}
        className={animationOptions}
      >
        {error && error.title ? error.title : title}
      </Typography>
      {error && (error.message || error.title) && retry ? (
        <div style={{ display: "flex", justifyContent: "center" }}>{retry}</div>
      ) : null}
    </div>
  );
}
