import React from "react";

// Interfaces
import { IPoint } from "../../../interfaces";

// MUI
import { Typography, Button } from "@material-ui/core";

// Util
import { find } from "lodash";

interface ILightControl {
  points: IPoint[];
}

const LightControl = ({ points }: ILightControl) => {
  const light = find(points, ["dataObjectType", "lightSwitch"]);
  return light ? (
    <div
      style={{ display: "flex", alignItems: "center", paddingRight: ".5rem" }}
    >
      <Button>
        {light.data.value ? (
          <i className="fas fa-lightbulb" style={{ fontSize: "1.25rem" }}></i>
        ) : (
          <i className="far fa-lightbulb" style={{ fontSize: "1.25rem" }}></i>
        )}
        <Typography
          variant="h6"
          style={{ fontWeight: 200, paddingLeft: ".5rem" }}
        >
          {light.data.value ? "On" : "Off"}
        </Typography>
      </Button>
    </div>
  ) : null;
};

export default LightControl;
