import React from "react";

// MUI
import {
  makeStyles,
  Card,
  CardActions,
  CardContent,
  Typography,
  Tooltip,
  IconButton,
  Button,
} from "@material-ui/core";

// MUI Icons
import { Star, StarOutlined } from "@material-ui/icons";

// Interfaces
import { IDevice, IPoint } from "../../interfaces";

// UTIL
import { find } from "lodash";
import { useSnackbar } from "notistack";

// Components
import DeviceDialog from "./DeviceDialog";
import TempControl from "./DraggableGrid/TempControl";

// Styles
const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "relative",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    textAlign: "center",
    fontSize: "1.25rem",
  },
  pos: {
    marginBottom: 12,
  },
  infoContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
  },
  addToHome: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "1rem",
    display: "flex",
    alignItems: "center",
  },
});

interface IPointsContainer {
  points: IPoint[];
}
const PointsTooltip = ({ points }: IPointsContainer) => {
  return (
    <span>
      {points.map((point: IPoint) => {
        return <p key={point.id}>{point.name}</p>;
      })}
    </span>
  );
};

const LightControl = ({ points }: IPointsContainer) => {
  const light = find(points, ["dataObjectType", "lightSwitch"]);
  return light ? (
    <div
      style={{ display: "flex", alignItems: "center", paddingRight: ".5rem" }}
    >
      <Button disabled={light.readOnly}>
        {light.data.value ? (
          <i className="fas fa-lightbulb" style={{ fontSize: "1.25rem" }}></i>
        ) : (
          <i className="far fa-lightbulb" style={{ fontSize: "1.25rem" }}></i>
        )}
        <Typography
          variant="h6"
          style={{ fontWeight: 200, paddingLeft: ".5rem" }}
        >
          {light.data.value ? "On" : "Off"}
        </Typography>
      </Button>
    </div>
  ) : null;
};

interface IDashboardDevice {
  data: IDevice;
  profile: any;
  updateProfile: (payload: any) => void;
}

const Device = ({ data, profile, updateProfile }: IDashboardDevice) => {
  const classes = useStyles();

  // Accessors
  const { enqueueSnackbar } = useSnackbar();

  // Convenience
  const inLayout = !profile.layout
    ? false
    : find(profile.layout, ["i", data.id])
    ? true
    : false;

  // Add to Home
  const addToHomeCB = React.useCallback(
    (data: IPoint | IDevice, type: string, variant: string) => {
      let payload = profile.layout ? profile.layout : [];

      if (inLayout) {
        updateProfile({
          layout: payload.filter((layout: any) => layout.i !== data.id),
        });

        enqueueSnackbar(`${data.name} removed from home screen`, {
          autoHideDuration: 2000,
          variant: "info",
        });
        return;
      }

      updateProfile({
        layout: [
          ...payload,
          {
            i: data.id,
            x: 0,
            y: 0,
            w: variant === "device" ? 2 : 1,
            h: 1,
            minW: variant === "device" ? 2 : 1,
            maxW: 4,
            maxH: 1,
            data: {
              variant,
              space: data.spaceId,
              device: variant === "device" ? data.id : data.deviceId,
              type,
            },
          },
        ],
      });

      enqueueSnackbar(`${data.name} added to home screen`, {
        autoHideDuration: 2000,
        variant: "success",
      });
    },
    // eslint-disable-next-line
    [profile.layout]
  );

  return (
    <Card className={classes.root}>
      <CardContent
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          padding: "3rem 0 0 0",
        }}
      >
        <div>
          <Typography className={classes.title} color="textSecondary">
            {data.name}
          </Typography>
          <Tooltip title={<PointsTooltip points={data.points} />}>
            <Typography variant="body2" component="p" align="center">
              {`${data.points.length} Points`}
            </Typography>
          </Tooltip>
        </div>

        <IconButton
          className={classes.addToHome}
          onClick={() => addToHomeCB(data, "device", "device")}
        >
          {inLayout ? <Star style={{ color: "gold" }} /> : <StarOutlined />}
        </IconButton>
      </CardContent>
      <CardActions>
        <div style={{ display: "flex", flexGrow: 1 }}>
          <TempControl points={data.points} />
          <LightControl points={data.points} />
        </div>
      </CardActions>

      {/* Dialogs */}
      <div className={classes.infoContainer}>
        <DeviceDialog data={data} />
      </div>
    </Card>
  );
};

export default Device;
