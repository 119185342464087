import React from "react";

// Context
import { DataContext } from "../Contexts";

// Interfaces
import { ILayout, IDevice, IProfile } from "../../interfaces";

// Components
import GridComponent from "./DraggableGrid/GridComponent";
import GridMenu from "./DraggableGrid/GridMenu";
import { Responsive, WidthProvider } from "react-grid-layout";

// Util
import { find } from "lodash";
import { Paper, makeStyles, Theme } from "@material-ui/core";
import { useFirebase } from "react-redux-firebase";

const useStyles = makeStyles((theme: Theme) => ({
  gridMenuContainer: {
    zIndex: 9,
    position: "absolute",
    top: 0,
    left: 0,
  },
}));

const ResponsiveGridLayout = WidthProvider(Responsive);

interface IDraggableGrid {
  layout: ILayout[];
  profile: IProfile;
  currentLayout: any;
  setCurrentLayout: any;
  editing: boolean;
}

const DraggableGrid = ({
  layout,
  profile,
  currentLayout,
  setCurrentLayout,
  editing,
}: IDraggableGrid) => {
  const classes = useStyles();
  const firebase = useFirebase();

  const { building } = React.useContext(DataContext);

  const findPoint = (id: string) => {
    let point;
    building.devices.forEach((device: IDevice) => {
      if (id === device.id) {
        point = device;
      } else if (find(device.points, ["id", id])) {
        point = find(device.points, ["id", id]);
      }
    });
    return point;
  };

  const layouts = { sm: layout };

  const handleChangeLayout = (currentLayout: any, allLayouts: any) => {
    setCurrentLayout(currentLayout);
  };

  // Grid Menu Handler
  const handleRemove = (payload: any) => {
    firebase.updateProfile(payload);
  };

  return (
    <ResponsiveGridLayout
      isDraggable={editing}
      isResizable={editing}
      className="layout"
      layouts={layouts}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 6, md: 4, sm: 4, xs: 4, xxs: 2 }}
      autoSize={false}
      compactType={null}
      onLayoutChange={(currentLayout: any, allLayouts: any) =>
        handleChangeLayout(currentLayout, allLayouts)
      }
      style={{ marginBottom: "5rem" }}
    >
      {layout.map((layoutData: ILayout) => {
        const data = findPoint(layoutData.i);
        return (
          <Paper
            elevation={3}
            key={layoutData.i}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "1rem 1rem 0 1rem",
            }}
          >
            {!editing && (
              <div className={classes.gridMenuContainer}>
                <GridMenu
                  data={data}
                  layout={profile.layout}
                  handleRemove={handleRemove}
                />
              </div>
            )}
            <GridComponent layout={layoutData} data={data} />
          </Paper>
        );
      })}
    </ResponsiveGridLayout>
  );
};

export default DraggableGrid;
