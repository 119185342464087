import React, { memo } from "react";
import { connect } from "react-redux";

//Routes
import { Switch } from "react-router";
import { SnackbarProvider } from "notistack";

//Code Splitting
// import { asyncComponent } from "../hocs/asyncComponent";

//Components
import Footer from "../components/Footer";

// Contexts
import { ThemeContext } from "../components/Contexts";

// Pages
import Home from "../pages/Home";
import Landing from "../pages/Landing";

// MUI
import { Theme } from "@material-ui/core";

// Components
import { Layout, StyledRoute } from "../components/Layout";

// Interface
interface IRoutes {
  profile: any;
  role: any;
  theme: Theme;
}

//Routes
export const LANDING = "/";
export const DASHBOARD = "/dashboard";
export const HOME = "/home";

const Routes = ({ profile, role, theme }: IRoutes) => {
  // Layout
  const { layout } = React.useContext(ThemeContext);

  return (
    <div style={{ background: theme.palette.background.paper }}>
      {/* Notifications */}
      <SnackbarProvider autoHideDuration={2000} maxSnack={3}>
        <>
          {/* Providers */}

          <Layout component={layout} />
          {/* Router */}

          <Switch>
            {/* Root */}
            {/* Global Routes */}
            <StyledRoute component={Landing} exact path={LANDING} />

            {/* /alarms */}
            <StyledRoute component={Home} exact path={HOME} />
          </Switch>
          <Footer />
        </>
      </SnackbarProvider>
    </div>
  );
};

export default connect((state) => ({
  // @ts-ignore
  profile: state.firebase.profile,
  // @ts-ignore
  role: state.firebase.profile.role,
}))(memo(Routes));
