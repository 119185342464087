import React, { ReactElement } from "react";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import { useFirebase } from "react-redux-firebase";

import { useSnackbar } from "notistack";

import {
  Button,
  Dialog,
  DialogActions,
  IconButton,
  useTheme,
  useMediaQuery,
  Slide,
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Tooltip,
  Grid,
} from "@material-ui/core";

// MUI Icon
import { InfoOutlined, StarBorder, ArrowLeft, Star } from "@material-ui/icons";

// Interfaces
import { IDevice, IPoint } from "../../interfaces";

// Components
import { TransitionProps } from "@material-ui/core/transitions";
import Temperature from "./Temperature";

// Models
import { find } from "lodash";
import Lights from "./Lights";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    toolbar: {
      background: theme.palette.background.paper,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    dialogContent: {
      display: "flex",
      padding: "2rem",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    deviceContainer: {
      position: "relative",
      maxWidth: 280,
      padding: "1rem 0",
    },
    homeContainer: {
      position: "absolute",
      top: 225,
      left: "50%",
      zIndex: 99,
    },
  })
);

interface IDeviceDialog {
  data: IDevice;
  icon?: ReactElement;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const DeviceDialog = ({ data, icon }: IDeviceDialog) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Home Screen Data
  const firebase = useFirebase();
  const profile = useSelector((state: any) => state.firebase.profile);

  // console.log(data.points);
  const tempPoints = data.points.filter(
    (point: IPoint) => point.dataObjectType === "temperature"
  );

  // console.log(data.points);
  const lightPoints = data.points.filter(
    (point: IPoint) => point.dataObjectType === "lightSwitch"
  );

  const handleClose = () => {
    setOpen(false);
  };

  // Add to Home
  const addToHome = (point: IPoint, type: string, variant: string) => {
    let payload = profile.layout ? profile.layout : [];

    if (find(payload, ["i", point.id])) {
      firebase.updateProfile({
        layout: payload.filter((layout: any) => layout.i !== point.id),
      });
      enqueueSnackbar(`${point.name} removed from home screen`, {
        autoHideDuration: 2000,
        variant: "info",
      });
      return;
    }

    firebase.updateProfile({
      layout: [
        ...payload,
        {
          i: point.id,
          x: 0,
          y: 0,
          w: 1,
          h: 1,
          minW: 1,
          maxW: 4,
          maxH: 1,
          data: {
            variant,
            space: point.spaceId,
            device: point.deviceId,
            type,
          },
        },
      ],
    });

    enqueueSnackbar(`${point.name} added to home screen`, {
      autoHideDuration: 2000,
      variant: "success",
    });
  };

  return (
    <div>
      <IconButton onClick={() => setOpen(true)}>
        {icon ? icon : <InfoOutlined />}
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        keepMounted
        TransitionComponent={Transition}
        fullScreen={fullScreen}
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <ArrowLeft />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {data.name}
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container className={classes.dialogContent}>
          {tempPoints.map((point: IPoint) => {
            const hasPoint = !profile.layout
              ? false
              : find(profile.layout, ["i", point.id])
              ? true
              : false;
            return (
              <Grid
                key={point.id}
                item
                xs={12}
                sm={6}
                className={classes.deviceContainer}
              >
                <Temperature data={point} />
                <div className={classes.homeContainer}>
                  <Tooltip title="Add to Home Screen">
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => addToHome(point, "temperature", "point")}
                      style={{ padding: 0 }}
                    >
                      {hasPoint ? (
                        <Star style={{ color: "gold" }} />
                      ) : (
                        <StarBorder />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              </Grid>
            );
          })}

          {lightPoints.map((point: IPoint) => {
            const hasPoint = !profile.layout
              ? false
              : find(profile.layout, ["i", point.id])
              ? true
              : false;
            return (
              <Grid
                key={point.id}
                item
                xs={12}
                sm={6}
                className={classes.deviceContainer}
              >
                <Lights data={point} />
                <div className={classes.homeContainer}>
                  <Tooltip title="Add to Home Screen">
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => addToHome(point, "temperature", "point")}
                      style={{ padding: 0 }}
                    >
                      {hasPoint ? (
                        <Star style={{ color: "gold" }} />
                      ) : (
                        <StarBorder />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              </Grid>
            );
          })}
        </Grid>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default compose(
  connect((state: any) => ({
    firestore: state.firestore,
  }))
)(DeviceDialog);
