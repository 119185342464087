import React, { memo, useContext } from "react";
/*

DEV TESTING START

*/

// import { useRenderCount } from "../testing";

/*

DEV TESTING END

*/

// Cloud functions

// Redux
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { SET_CLIENTS, SET_BUILDING_DATA } from "../actions";
import { LANDING } from "../routes";

// MUI
import {
  Button,
  Grid,
  Tabs,
  Tab,
  // Hooks / Interfaces
  Theme,
  makeStyles,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@material-ui/core";

// MUI Icons
import { Edit } from "@material-ui/icons";

// Components
import Loader from "../components/Loader";

// Contexts
import { DataContext } from "../components/Contexts";

// API
import { tapaAPI } from "../api";

// Interfaces
import {
  IBuildingData,
  IDashboard,
  IDashboardClient,
  ILayout,
} from "../interfaces";

// Components
import DragabbleGrid from "../components/Home/DraggableGrid";
import MobileAppBar from "../components/Home/MobileAppBar";
import Dashboard from "../components/Home/Dashboard";

// Util
import { find } from "lodash";
import { useFirebase } from "react-redux-firebase";
import { useSnackbar } from "notistack";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1rem",
    fontWeight: 500,
    width: "100%",
    overflow: "hidden",
  },
  tabContainer: {
    width: "100%",
    justifyContent: "space-evenly",
  },
  tab: {
    flexGrow: 1,
  },
  expandButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  // Generic
  fullWidth: {
    width: "100%",
  },
  flex: {
    display: "flex",
    flexWrap: "wrap",
  },
  tabComponent: {
    width: "100%",
    height: 650,
    padding: "1rem",
    overflowY: "auto",
  },
  flexContainer: {
    justifyContent: "space-evenly",
  },
}));

// Mobile Router
interface IMobileRoute {
  children: any;
  route: string;
  value: string;
}
const MobileRoute = ({ children, route, value }: IMobileRoute) => {
  if (route === value) {
    return <div style={{ width: "100%" }}>{children}</div>;
  }
  return null;
};

interface IHomeTabWrapper {
  children: any;
  tab: number;
  value: number;
}
const TabWrapper = ({ children, tab, value }: IHomeTabWrapper) => {
  if (tab === value) {
    return <div style={{ width: "100%" }}>{children}</div>;
  }
  return null;
};

// Memo equality check
const areEqual = (
  prevProps: Readonly<IDashboard>,
  nextProps: Readonly<IDashboard>
): any => {
  return (
    prevProps.auth.isEmpty === nextProps.auth.isEmpty &&
    prevProps.profile.isEmpty === nextProps.profile.isEmpty &&
    prevProps.profile.layout === nextProps.profile.layout &&
    prevProps.clients.length === nextProps.clients.length &&
    prevProps.buildingData === nextProps.buildingData
  );
};

// Render
const Home = ({ clients, profile, setClients, reroute }: IDashboard) => {
  const firebase = useFirebase();
  const { enqueueSnackbar } = useSnackbar();

  // Styles
  const classes = useStyles();

  // Component Status
  const [fetchingClient, setFetchingClient] = React.useState(false);

  // Status
  // Selected Client / Building App-wide (from Context in Router)
  // eslint-disable-next-line
  const { space } = useContext(DataContext);

  // eslint-disable-next-line
  const [error, setError] = React.useState({ title: "", message: "" });

  // Tab controls
  const [value, setValue] = React.useState(0);

  // Router controls
  const [route, setRoute] = React.useState("/home");

  // Grid Layout
  const [currentLayout, setCurrentLayout] = React.useState({});
  const [editing, setEditing] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  /*

  CLIENT API

  */
  const fetchClients = () => {
    if (error.title || error.message) {
      setError({ title: "", message: "" });
    }
    setFetchingClient(true);
    tapaAPI({
      route: "clients",
      api: profile.api,
      callback: handleSetClients,
      error: () =>
        setError({
          title: "Error Fetching Buildings",
          message: "This has been logged and we are working on a solution",
        }),
    });
  };

  // fetchingClients callback
  const handleSetClients = (clients: any) => {
    setClients(clients);
    setFetchingClient(false);
  };

  // Handle Set Route
  const handleSetRoute = React.useCallback(
    (route: string) => {
      setRoute(route);
    },
    [setRoute]
  );

  // Tab * Settings *
  const handleEditing = () => {
    setEditing(true);
    setValue(0);
  };

  // Update Layout
  const handleUpdate = () => {
    let updatedLayout: ILayout[] = [];

    // Update Layouts
    profile.layout.forEach((layout: ILayout) => {
      let editedLayout = find(currentLayout, ["i", layout.i]) || {
        x: 0,
        y: 0,
        w: 0,
      };
      let newProfileLayout = { ...layout };
      if (editedLayout) {
        newProfileLayout = {
          ...layout,
          x: editedLayout.x,
          y: editedLayout.y,
          w: editedLayout.w,
        };
        updatedLayout.push(newProfileLayout);
      }
    });

    firebase.updateProfile({
      layout: updatedLayout,
    });

    enqueueSnackbar("Home Page Updated", {
      variant: "success",
      autoHideDuration: 2000,
    });
    setEditing(false);
  };

  // Loading Catch
  if (fetchingClient || !clients.length) {
    return (
      <Loader
        title={"Fetching Clients"}
        trigger={!fetchingClient || !clients.length}
        error={error}
        retry={
          error && (
            <Button onClick={() => fetchClients()} variant="outlined">
              Reconnect
            </Button>
          )
        }
      />
    );
  }

  if (!space.id) {
    reroute(LANDING);
  }

  // Render
  return (
    <Grid container className={classes.container}>
      {/* Bottom AppBar */}
      <MobileAppBar
        route={route}
        handleSetRoute={handleSetRoute}
        editing={editing}
        setEditing={setEditing}
        handleUpdate={handleUpdate}
      />

      {/* Routes: Home */}
      <MobileRoute value="/home" route={route}>
        <div className={classes.tabContainer}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            classes={{ flexContainer: classes.flexContainer }}
          >
            <Tab label="Home" className={classes.tab} />
            <Tab label="Settings" className={classes.tab} disabled={editing} />
          </Tabs>

          {/* Home */}
          <TabWrapper tab={0} value={value}>
            {profile.layout ? (
              <div className={classes.tabComponent}>
                <DragabbleGrid
                  layout={profile.layout}
                  profile={profile}
                  currentLayout={currentLayout}
                  setCurrentLayout={setCurrentLayout}
                  editing={editing}
                />
              </div>
            ) : null}
          </TabWrapper>

          {/* Settings */}
          <TabWrapper tab={1} value={value}>
            <div className={classes.tabComponent}>
              <List>
                <ListItem button onClick={() => handleEditing()}>
                  <ListItemAvatar>
                    <Avatar>
                      <Edit />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Arrange"
                    secondary="Edit and Arrange Homescreen"
                  />
                </ListItem>
              </List>
            </div>
          </TabWrapper>
        </div>
      </MobileRoute>

      {/* Routes: Dashboard */}
      <MobileRoute value="/dashboard" route={route}>
        <Dashboard />
      </MobileRoute>
    </Grid>
  );
};

export default connect(
  (state: any) => ({
    auth: state.firebase.auth,
    buildingData: state.api.buildingData,
    clients: state.api.clients,
    profile: state.firebase.profile,
  }),
  (dispatch: any) => ({
    reroute: (route: string) => dispatch(push(route)),
    setClients: (clients: Array<IDashboardClient>) =>
      dispatch({ type: SET_CLIENTS, payload: clients }),
    setBuildingData: (building: IBuildingData) =>
      dispatch({ type: SET_BUILDING_DATA, payload: building }),
  })
)(memo(Home, areEqual));
