import React, { useContext } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

// Contexts
import { DataContext } from "../components/Contexts";

// Interfaces
import { IDashboardClient, IProfile } from "../interfaces";

// Cloud Functions
import { functions } from "../firebase/firebase";
import { LinearProgress } from "@material-ui/core";

// Fetch Function
const fetchEndpoint = functions.httpsCallable("fetchEndpoint");

// Component Interface
interface ILanding {
  clients: IDashboardClient[];
  profile: IProfile;
  reroute: (route: string) => void;
}

const Landing = ({ clients, profile, reroute }: ILanding) => {
  const {
    client,
    setClient,
    site,
    setSite,
    building,
    setBuilding,
    floor,
    setFloor,
    space,
    setSpace,
  } = useContext(DataContext);
  const [fetchingBuilding, setFetchingBuilding] = React.useState(false);
  const [error, setError] = React.useState({ title: "", message: "" });

  // Establish data chain
  // Set Client
  /*

  DEV ONLY

  */
  React.useEffect(() => {
    const filteredClients = clients.filter(
      (c: any) => c.client.sites && c.client.sites.length
    );
    setClient(filteredClients[0].client);
    // eslint-disable-next-line
  }, []);
  /*

  DEV ONLY

  */

  // Set Site
  React.useEffect(() => {
    if (client) {
      setSite(client.sites[0]);
    }
    // eslint-disable-next-line
  }, [client]);

  // Set Building
  React.useEffect(() => {
    if (site && site.buildings && Boolean(site.buildings.length)) {
      fetchBuildings();
    }
    // eslint-disable-next-line
  }, [site]);

  // Set Floor
  React.useEffect(() => {
    if (building && building.floors.length) {
      setFloor(building.floors[0]);
    }
    // eslint-disable-next-line
  }, [building]);

  // Set Space
  React.useEffect(() => {
    if (floor && floor.spaces) {
      setSpace(floor.spaces[0]);
    }
    // eslint-disable-next-line
  }, [floor]);

  // Fetching building if none
  const fetchBuildings = () => {
    if (!profile.api) {
      setError({
        title: "No API",
        message:
          "Your API key could not be verified. Please log out and log in again.",
      });
      return;
    }

    // Clear errors
    if (error.title || error.message) {
      setError({ title: "", message: "" });
    }

    // Fetch buildings
    setFetchingBuilding(true);
    fetchEndpoint({
      route: `buildings/${site.buildings[0].id}`,
      api: profile.api,
    })
      .then((res: any) => {
        let data = JSON.parse(res.data);
        if (data && Boolean(data.length)) {
          setBuilding(data[0]);
        }
        setFetchingBuilding(false);
      })
      .catch((e: Error) => {
        setFetchingBuilding(false);
        error
          ? setError({
              title: "Error Fetching Buildings",
              message: "This has been logged and we are working on a solution",
            })
          : console.log(e);
      });
  };

  if (fetchingBuilding) {
    return <LinearProgress />;
  }

  if (space && space.id) {
    reroute("/home");
    return <span />;
  }

  return <div></div>;
};

export default connect(
  (state: any) => ({
    clients: state.api.clients,
    profile: state.firebase.profile,
  }),
  (dispatch) => ({
    reroute: (route: string) => dispatch(push(route)),
  })
)(Landing);
