import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

//Reducers
import apiReducer from "./api";
import sessionReducer from "./session";
import stylesReducer from "./styles";

const createRootReducer = (history: any) => {
  const reducerBase = combineReducers({
    api: apiReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    router: connectRouter(history),
    session: sessionReducer,
    status: stylesReducer
  });

  const rootReducer = (state: any, action: { type: string }) => {
    // if (action.type === "RESET_APP") {
    //   state = undefined;
    // }

    return reducerBase(state, action);
  };

  return rootReducer;
};

export default createRootReducer;
