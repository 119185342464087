import React from "react";

// MUI
import {
  Drawer,
  List,
  // Hooks
  makeStyles,
  Typography,
} from "@material-ui/core";

// Interfaces
import { IAlarm } from "../../interfaces";
import { Alarm } from ".";
import { ListSubheader } from "@material-ui/core";

const useStyles = makeStyles({
  list: {
    width: 300,
  },
});

interface IAlarmMenu {
  alarms: IAlarm[];
  open: boolean;
  handleClose: () => void;
}

const AlarmMenu = (props: IAlarmMenu) => {
  const classes = useStyles();

  const { alarms, open, handleClose } = props;

  return (
    <Drawer open={open} anchor="right" onClose={() => handleClose()}>
      <List
        subheader={<ListSubheader>Point Alerts</ListSubheader>}
        className={classes.list}
      >
        {!Boolean(alarms.length) ? (
          <Typography
            variant="h6"
            style={{ width: "100%", textAlign: "center", padding: "1rem" }}
          >
            You're all up to date!
          </Typography>
        ) : (
          alarms.map((alarm: IAlarm) => (
            <Alarm alarm={alarm} key={alarm.eventId} />
          ))
        )}
      </List>
    </Drawer>
  );
};

export default AlarmMenu;
